import { useAccess } from "@/hooks/useAccess";
import { ButtonWithLabel } from "@/shared/UI/ButtonWithLabel";
import SvgSelector from "@/shared/UI/SvgSelector";
import { isUnknown } from "@/shared/constants/access";
import {
  ROUTES,
  isLegalSlug,
  isNotPromo,
  isOnlyFoundationSlug,
  isOnlyHandbookSlug,
  isOnlyLegalSlug,
} from "@/shared/constants/routes";
import { breakpoints } from "@/styles/variables/variables";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import { AuthorizedUserDropdown } from "./AuthorizedUserDropdown";
import { UserDropdown } from "./UserDropdown";

export const Header = ({ logoSvg }) => {
  const { pathname, replace } = useRouter();

  const [access] = useAccess();

  return (
    <HeaderStyled
      data-aos={isNotPromo(pathname) ? "" : "fade"}
      data-aos-delay="800"
    >
      {isOnlyHandbookSlug(pathname) ? (
        <>
          <LinkLogo href={ROUTES.HANDBOOK}>
            <SvgSelector svg={logoSvg} />
          </LinkLogo>
          <ButtonWithLabel
            location="tl"
            handleClick={() => replace(ROUTES.HANDBOOK)}
            svg={"campus-button-with-label-back"}
          >
            Справочник
          </ButtonWithLabel>
        </>
      ) : isOnlyLegalSlug(pathname) ? (
        <>
          <LinkLogo href={ROUTES.LEGAL}>
            <SvgSelector svg={logoSvg} />
          </LinkLogo>
          <ButtonWithLabel
            location="tl"
            handleClick={() => replace(ROUTES.LEGAL)}
            svg={"campus-button-with-label-back"}
          >
            Документы
          </ButtonWithLabel>
        </>
      ) : isOnlyFoundationSlug(pathname) ? (
        <>
          <LinkLogo href={ROUTES.FOUNDATION}>
            <SvgSelector svg={logoSvg} />
          </LinkLogo>
          <ButtonWithLabel
            location="tl"
            handleClick={() => replace(ROUTES.FOUNDATION)}
            svg={"campus-button-with-label-back"}
          >
            Артподготовка
          </ButtonWithLabel>
        </>
      ) : (
        <SvgSelector svg={logoSvg} />
      )}

      {isUnknown(access) ? (
        <UserDropdown
          pageWithOpenedPopup={isNotPromo(pathname) && !isLegalSlug(pathname)}
        />
      ) : (
        <AuthorizedUserDropdown />
      )}
    </HeaderStyled>
  );
};

const LinkLogo = styled(Link)`
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.7;
  }
  & > svg {
    @media ${breakpoints.desktopXl} {
      height: 3.2vw;
      width: auto;
    }
    @media ${breakpoints.tabletL} {
      height: 4vw;
    }
    @media ${breakpoints.tabletM} {
      height: 5.5vw;
    }
    @media ${breakpoints.mobile} {
      height: 10.031vw;
      position: relative;
      z-index: 10;
    }
  }
`;

const HeaderStyled = styled.header`
  padding-top: 24px;
  text-align: center;
  position: relative;
  @media ${breakpoints.mobile} {
    padding-top: 5.016vw;
  }
  & > svg {
    @media ${breakpoints.desktopXl} {
      height: 3.2vw;
      width: auto;
    }
    @media ${breakpoints.tabletL} {
      height: 4vw;
    }
    @media ${breakpoints.tabletM} {
      height: 5.5vw;
    }
    @media ${breakpoints.mobile} {
      height: 10.031vw;
      position: relative;
      z-index: 10;
    }
  }
`;
