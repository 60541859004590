import { PulsedHeart } from "@/components/index-page/payment/Heart";
import { useAccess } from "@/hooks/useAccess";
import { breakpoints, mixins } from "@/styles/variables/variables";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { isFull } from "../constants/access";
import SvgSelector from "./SvgSelector";

export const ButtonWithLabel = ({
  handleClick,
  open = false,
  svg = undefined,
  children,
  location,
  hide = false,
  promo = false,
}) => {
  const [hovered, setHovered] = useState(false);

  const isMobile = useMediaQuery(breakpoints.mobile);

  const [access] = useAccess();

  return (
    <Button
      promo={promo}
      hide={hide}
      location={location}
      onClick={handleClick}
      open={open}
      disable={location === "tr" && !isFull(access)}
    >
      <ButtonInside
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        open={open}
        payment={location === "cl"}
      >
        {location !== "cl" ? <SvgSelector svg={svg} /> : <PulsedHeart />}
      </ButtonInside>
      {isMobile || (
        <Label payment={location === "cl"} show={hovered}>
          {children}
        </Label>
      )}
    </Button>
  );
};

const ButtonInside = styled.span`
  position: relative;
  cursor: pointer;

  &:hover {
    & > svg path:first-child {
      fill-opacity: 0.04;
    }

    ${({ payment }) =>
      payment
        ? `& > div::before {
      animation-duration: 0.8s;
    };`
        : `& stop {
      stop-opacity: 0.04;
    };
    `};
  }
  &:active {
    & > svg path:first-child {
      fill-opacity: 0.08;
    }
  }

  & > svg {
    pointer-events: none;
    & path {
      transition: fill-opacity 0.3s;
    }

    width: 42px;
    height: 42px;

    @media ${breakpoints.desktopXl} {
      width: 3.284vw;
      height: 3.284vw;
    }
    @media ${breakpoints.tabletL} {
      width: 4.106vw;
      height: 4.106vw;
    }
    @media ${breakpoints.tabletM} {
      width: 5.653vw;
      height: 5.653vw;
    }
    @media ${breakpoints.mobile} {
      width: 11.285vw;
      height: 11.285vw;
      /* & path:first-child {
        fill-opacity: 0.04;
      }
      & stop {
        stop-opacity: 0.04;
      } */
    }
  }
`;

const Label = styled.span`
  pointer-events: none;
  opacity: 0;
  transition:
    opacity 0.2s,
    transform 0.5s,
    background-color 0.3s;
  ${mixins.fs12};
  line-height: 120%;
  letter-spacing: 0.24px;
  border-radius: 21px;
  background-color: var(--wh04);
  padding: 5px 12px;
  color: var(--wh6);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  position: absolute;
  ${({ show }) => show && `opacity: 1; transform: translateX(0) !important; `};
  ${({ payment }) =>
    payment && `color: #71325B; background-color: var(--wh9);`};
`;

const Button = styled.div`
  z-index: 1300;
  display: flex;
  ${mixins.gap8}
  align-items: center;
  transition: opacity 0.3s;
  ${({ hide }) => hide && `opacity:0; pointer-events:none; `}
  ${({ location, open, promo, disable }) => {
    switch (location) {
      case "bl":
        return `
            position: fixed;
            bottom: 20px;
            left: 16px;
            @media ${breakpoints.desktopXl} {
                bottom: 1.564vw;
                left: 1.251vw;
            }
            @media ${breakpoints.tabletL} {
                bottom: 1.955vw;
                left: 1.564vw;
            }
            @media ${breakpoints.tabletM} {
                bottom: 2.692vw;
                left: 2.153vw;
            }
            @media ${breakpoints.mobile} {
              
                ${
                  promo
                    ? `left:2.508vw; 
              bottom: 4.389vw;
              position: absolute;
              `
                    : `bottom: initial;
                top: 33.229vw;
                right: 2.508vw;
                left: initial;`
                }
            }
            ${Label} {
              left: 120%;
                transform: translateX(-20px);
            }
      `;
      case "cr":
        return ` 
                position: fixed;
                bottom: 50%;
                transform: translateY(50%);
            right: 16px;
            @media ${breakpoints.desktopXl} {
              right: 1.251vw;
            }
            @media ${breakpoints.tabletL} {
                right: 1.564vw;
            }
            @media ${breakpoints.tabletM} {
                right: 2.153vw;
            }
            @media ${breakpoints.mobile} {
                right: 2.508vw;
            }
            ${Label} {
              right: 120%;
                order: -1;
                transform: translateX(20px);
            }
      `;
      case "cl":
        return ` 
                position: fixed;
                bottom: 50%;
                transform: translateY(50%);
            left: 16px;
            @media ${breakpoints.desktopXl} {
              left: 1.251vw;
            }
            @media ${breakpoints.tabletL} {
                left: 1.564vw;
            }
            @media ${breakpoints.tabletM} {
                left: 2.153vw;
            }
            @media ${breakpoints.mobile} {
              right: 0.627vw;
              bottom: 31.975vw;
                transform: none;
                left: initial;
            }
            ${Label} {
              left: 120%;
                order: -1;
                transform: translateX(-20px);
            }
      `;
      case "tr":
        return `
            ${disable && "pointer-events:none;"};
            ${Label} {
              right: 120%;
                order: -1;
                transform: translateX(20px);
            }
            & svg {
               & path:nth-child(2) {
                  ${open ? `fill-opacity: 0.6;` : ``}
               } 
               & path:nth-child(3) {
                  ${disable && "stroke-opacity: 0.2;"}
               } 
            }
      `;
      case "tr-chat":
        return `
            position: fixed;
            top: 76px;
            right: 19px;
            @media ${breakpoints.desktopXl} {
              right: 1.49vw;
              top: 5.94vw;
            }
            @media ${breakpoints.tabletL} {
              top: 7.43vw;
              right: 1.86vw;
            }
            @media ${breakpoints.tabletM} {
              top: 10.23vw;
                right: 2.56vw;
            }
            @media ${breakpoints.mobile} {
                right: 2.51vw;
                top: 19.44vw;
            }
            ${Label} {
              right: 120%;
                order: -1;
                transform: translateX(20px);
            }
      `;
      case "tl":
        return ` 
            position: fixed;
            top: 20px;
            left: 16px;
            @media ${breakpoints.desktopXl} {
              top: 1.56vw;
              left: 1.25vw;
            }
            @media ${breakpoints.tabletL} {
              top: 1.96vw;
              left: 1.56vw;
            }
            @media ${breakpoints.tabletM} {
              top: 2.69vw;
              left: 2.15vw;
            }
            @media ${breakpoints.mobile} {
              position: absolute;
              top: 3.76vw;
              left: 2.51vw;
            }
            ${Label} {
              left: 120%;
                order: -1;
                transform: translateX(-20px);
            }
      `;
      case "tl-order":
        return ` 
            position: fixed;
            top: 20px;
            left: 16px;
            @media ${breakpoints.desktopXl} {
              top: 1.56vw;
              left: 1.25vw;
            }
            @media ${breakpoints.tabletL} {
              top: 1.96vw;
              left: 1.56vw;
            }
            @media ${breakpoints.tabletM} {
              top: 2.69vw;
              left: 2.15vw;
            }
            @media ${breakpoints.mobile} {
            position: absolute;
              top: -10.97vw;
              left: 2.51vw;
            }
            ${Label} {
              left: 120%;
                order: -1;
                transform: translateX(-20px);
            }
      `;
    }
  }}
   @media ${breakpoints.mobile} {
    height: 11.285vw;
  }
`;
