import { TgButton } from "@/components/auth/TgButton";
import { setOpenAlertRetry, setRetryTime } from "@/redux/alertRetrySlice";
import api from "@/services/api";
import SvgSelector from "@/shared/UI/SvgSelector";
import { documentRoutes } from "@/shared/constants/documentRoutes";
import { helpLink } from "@/shared/constants/links";
import { breakpoints } from "@/styles/variables/variables";
import { Fade, useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { VALID_STATE } from "./LoginTabs";
import {
  ButtonRegister,
  ButtonSend,
  EmailInput,
  ErrorLabel,
  Help,
  HideWrapper,
  InputWrapper,
  LoginLabel,
  NameInput,
  Policy,
  SocialList,
} from "./components";

export const TabContent = ({
  isRegister = false,
  setValid,
  valid,
  bind = false,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [hideSocial, setHideSocial] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();

  const { pathname } = useRouter();

  const checkIsValid = (input) => {
    if (valid !== VALID_STATE.neutral) {
      if (isRegister) {
        input.value = "";
        setDisableButton(true);
      } else {
        input.form.reset();
      }
      setValid(VALID_STATE.neutral);

      input.focus();
      return;
    }
    if (input.validity.patternMismatch) {
      setErrorMessage(
        "Чёт не похоже на электропочту, где‑то ошибка, проверьте, попробуйте снова или напишите нам"
      );
      setValid(VALID_STATE.bad);
      input.form[0].blur();
    } else if (!input.validity.valid) {
      setErrorMessage("Заполните поле");
      setValid(VALID_STATE.bad);
      input.blur();
    } else {
      const formData = new FormData(input.form);
      formData.append("next", pathname); // обратная ссылка в письме
      const formProps = Object.fromEntries(formData);

      api
        .post(
          `/api/auth/email/${
            bind ? "connect" : isRegister ? "register" : "login"
          }/`,
          formProps
        )
        .then(
          () => {
            setErrorMessage(
              `Отправили письмо со ссылкой для ${
                bind
                  ? "подтверждения привязки почты"
                  : "входа в аккаунт на эту почту"
              }, проверьте входящие и спам`
            );
            setValid(VALID_STATE.good);
          },
          (error) => {
            const status = error.response.status;
            let errorStr;
            if (status === 429) {
              dispatch(setOpenAlertRetry(true));
              dispatch(setRetryTime(error?.response?.headers["retry-after"]));
            } else if (status === 400) {
              if (
                error.response.data.non_field_errors[0]?.code === "user_exists"
              ) {
                errorStr =
                  "Такая почта уже привязана к другому аккаунту, чтобы объединить аккаунты, напишите нам";
              } else {
                errorStr = isRegister
                  ? "Такая электропочта у нас уже есть, войдите через неё в аккаунт или напишите нам"
                  : "Такой электропочты у нас нет, где-то ошибка, проверьте, попробуйте снова или напишите нам";
              }
            }
            setErrorMessage(errorStr);
            setValid(VALID_STATE.bad);
            input.blur();
            console.log("произошла ошибка отправки email: ", error);
          }
        );

      return true;
    }
    return false;
  };
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <>
      <Fade in={true} timeout={700} style={{ transitionDelay: 140 }}>
        <form
          noValidate
          id="user-register"
          onSubmit={(e) => {
            e.preventDefault();
            const input = e.target[0];
            checkIsValid(input);
          }}
          onKeyDown={(e) => {
            if (isRegister && e.key === "Enter" && disableButton) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (isRegister) {
              setDisableButton(
                Array.from(e.target.form).some(
                  (input) =>
                    input.tagName === "INPUT" && input.value.length === 0
                )
              );
            }
          }}
        >
          <InputWrapper
            onFocus={(e) => {
              setHideSocial(true);
              if (e.target.tagName === "INPUT") setValid(VALID_STATE.neutral);
            }}
            onBlur={() => setHideSocial(false)}
          >
            <EmailInput
              valid={valid}
              autocomplete="off"
              placeholder="Электропочта"
              type="email"
              name="email"
              id="user-email"
              required
              pattern="(?=.*@[A-Za-z]+\.)(@|[A-Za-z0-9]|-|_|\.)+"
              onChange={(e) => {
                if (!isRegister) {
                  const buttonDiv = e.target.form[1].children[0];
                  buttonDiv.style.display =
                    e.target.value !== "" ? "flex" : "none";
                }
              }}
            />
            <ButtonSend disabled={valid === VALID_STATE.good} type="submit">
              {valid === VALID_STATE.bad ? (
                <SvgSelector svg="login-invalid-send" />
              ) : valid === VALID_STATE.good ? (
                <SvgSelector svg="login-good-send" />
              ) : (
                isRegister || (
                  <div style={{ display: "none" }}>
                    <SvgSelector svg="login-button-send" />
                  </div>
                )
              )}
            </ButtonSend>
          </InputWrapper>
          {isRegister && valid !== VALID_STATE.good && (
            <InputWrapper
              onFocus={(e) => {
                setHideSocial(true);
                if (e.target.tagName === "INPUT") setValid(VALID_STATE.neutral);
              }}
              onBlur={() => setHideSocial(false)}
            >
              <NameInput
                valid={valid}
                autocomplete="off"
                placeholder="Имя Фамилия"
                maxLength={30}
                type="text"
                name="display_name"
                id="user-name"
                required
                pattern="[A-ZА-ЯЁ]([A-Za-zА-ЯЁа-яё]|-)*"
              />
            </InputWrapper>
          )}

          <ErrorLabel valid={valid}>{errorMessage}</ErrorLabel>
        </form>
      </Fade>
      {!bind && valid === VALID_STATE.neutral && (
        <>
          <HideWrapper hide={hideSocial}>
            <Fade in={true} timeout={700} style={{ transitionDelay: 210 }}>
              <SocialList>
                <TgButton register={isRegister} />
                {/* {social.map((link, i) => (
                  <SocialLink
                    href={link.url}
                    key={i}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SvgSelector svg={`login-base`} />
                    <SvgSelector svg={`login-${link.type}`} />
                  </SocialLink>
                ))} */}
              </SocialList>
            </Fade>
            <Fade in={true} timeout={700} style={{ transitionDelay: 280 }}>
              <div>
                <LoginLabel>
                  {isRegister ? "Зарегайтесь" : "Войдите"} через вашу
                  электропочту <br />
                  или через телеграм
                </LoginLabel>
                <Policy target="_blank" href={documentRoutes.terms}>
                  Политика конфиденциальности
                </Policy>
              </div>
            </Fade>
          </HideWrapper>
          {isRegister && (
            <ButtonRegister
              onMouseDown={(e) => {
                if (document.activeElement.tagName === "INPUT")
                  e.preventDefault();
              }}
              disabled={disableButton}
              type="submit"
              form="user-register"
              hide={hideSocial}
            >
              Зарегаться
            </ButtonRegister>
          )}
        </>
      )}
      {isMobile && (
        <Fade in={true} timeout={700} style={{ transitionDelay: 350 }}>
          <Help
            valid={valid}
            href={helpLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Помощь
          </Help>
        </Fade>
      )}
    </>
  );
};
