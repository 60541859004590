import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import { VALID_STATE } from "./LoginTabs";
import Link from 'next/link';

export const TabsStyled = styled(Tabs)`
  min-height: auto !important;
  overflow: visible !important;
  width: 100%;

  & span {
    display: none !important;
  }
  & .Mui-selected {
    ${({ valid }) => {
      switch (valid) {
        case VALID_STATE.neutral:
          return `
      color: var(--wh9) !important;
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
      `;
        case VALID_STATE.bad:
          return `
      color: var(--orange9) !important;
      text-shadow: 0px 0px 32px rgba(231, 127, 69, 0.60);
      `;
        case VALID_STATE.good:
          return `
      color: var(--green9) !important;
      text-shadow: 0px 0px 32px var(--green6);
      `;
      }
    }}
  }
  & .MuiTabs-flexContainer {
    display: grid;
    grid-template-columns: min-content min-content auto;
    align-items: center;
    ${mixins.gap8}
    @media ${breakpoints.mobile} {
      justify-content: center;
    }
  }
  & .MuiTabs-scroller {
    overflow: visible !important;
  }
`;

export const Box = styled.div`
  padding: 24px;
  border-radius: 32px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 25.5px;
  width: 373px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);

  @media ${breakpoints.desktopXl} {
    padding: 1.876vw;
    width: 29.14vw;
    gap: 1.99vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 2.346vw;
    width: 36.4vw;
    gap: 2.49vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 3.23vw;
    width: 50.13vw;
    gap: 3.42vw;
  }
  @media ${breakpoints.mobile} {
    padding: 7.524vw;
    width: 100%;
    height: 100%;
    gap: 7.5vw;
    border-radius: 0;
    justify-content: center;
    background-color: #141414;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.32);
  }

  ${({ valid }) => {
    switch (valid) {
      case VALID_STATE.neutral:
        return `
      border: 1px solid var(--wh04);
      background-color: var(--wh04);
    `;
      case VALID_STATE.bad:
        return `
      border: 1px solid #23150D;
      background-color: var(--orange04);
    `;
      case VALID_STATE.good:
        return `
      border: 1px solid var(--greenDark);
      background-color: var(--green04);
    `;
    }
  }}

  & > div {
    width: 100%;
  }
`;

export const TabStyled = styled(Tab)`
  padding: 0 !important;
  min-width: auto !important;
  font-family: ArtelSans !important;
  font-style: normal;
  text-transform: none !important;
  font-weight: bold !important;
  min-height: auto !important;
  font-size: 20px !important;
  letter-spacing: -0.2px !important;
  transition: color 0.2s, text-shadow 0.3s;
  overflow: visible !important;
  @media ${breakpoints.desktopXl} {
    font-size: 1.562vw !important;
  }
  @media ${breakpoints.tabletL} {
    font-size: 1.95vw !important;
  }
  @media ${breakpoints.tabletM} {
    font-size: 2.68vw !important;
  }
  @media ${breakpoints.mobile} {
    font-size: 6.25vw !important;
  }

  ${(props) =>
    props.shake &&
    `
    animation: shake 0.5s 1;
    `}

  ${({ valid }) => {
    switch (valid) {
      case VALID_STATE.neutral:
        return `
      color: var(--wh3) !important;
      `;
      case VALID_STATE.bad:
        return `
      color: var(--orange4) !important;
      `;
      case VALID_STATE.good:
        return `
      color: var(--green3) !important;
      `;
    }
  }}
  &:hover {
    color: var(--wh9);

    ${({ valid }) => {
      switch (valid) {
        case VALID_STATE.neutral:
          return `
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
      `;
        case VALID_STATE.bad:
          return `
      text-shadow: 0px 0px 32px rgba(231, 127, 69, 0.60);
      `;
        case VALID_STATE.good:
          return `
      text-shadow: 0px 0px 32px var(--green6);
      `;
      }
    }}
  }

  @keyframes shake {
    0% {
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
    }
    30% {
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.7);
    }
    50% {
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.9);
    }
    70% {
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.7);
    }
    100% {
      text-shadow: 0px 0px 32px rgba(255, 255, 255, 0.32);
    }
  }
`;

export const Help = styled.a`
  justify-self: end;
  ${mixins.fs10};
  line-height: 120%;
  letter-spacing: 0.2px;
  transition: color 0.3s;
  ${({ valid }) => {
    switch (valid) {
      case VALID_STATE.neutral:
        return `
      color: var(--wh3);
      &:hover {
        color: var(--wh6);
      }
      `;
      case VALID_STATE.bad:
        return `
        color: var(--orange4);
        &:hover {
          color: var(--orange9);
        }
        `;
      case VALID_STATE.good:
        return `
          color: var(--green3);
          &:hover {
            color: var(--green6);
          }
      `;
    }
  }}

  &:active {
    color: var(--wh3);
  }

  @media ${breakpoints.mobile} {
    display: block;
    text-align: center;
    margin-top: 7.5vw;
    padding: 2.5vw 1.25vw;
  }
`;
export const HideWrapper = styled.div`
  transition: height 0.3s, opacity 0.3s, margin-top 0.3s;
  margin-top: 16px;
  height: 126px;
  @media ${breakpoints.desktopXl} {
    height: 9.851vw;
    margin-top: 1.25vw;
  }
  @media ${breakpoints.tabletL} {
    height: 12.317vw;
    margin-top: 1.56vw;
  }
  @media ${breakpoints.tabletM} {
    height: 16.958vw;
    margin-top: 2.15vw;
  }
  @media ${breakpoints.mobile} {
    height: 39.498vw;
    margin-top: 5vw;
  }
  ${({ hide }) =>
    hide &&
    `
    margin-top: 0px !important;
    height: 0px !important;
    opacity: 0 !important;
    pointer-events: none;
    `}
`;
export const SocialList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media ${breakpoints.desktopXl} {
    margin-bottom: 1.875vw;
  }
  @media ${breakpoints.tabletL} {
    margin-bottom: 2.34vw;
  }
  @media ${breakpoints.tabletM} {
    margin-bottom: 3.2vw;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 7.5vw;
  }
`;

export const LoginLabel = styled.p`
  color: var(--wh2);
  text-align: center;
  ${mixins.fs12};
  line-height: 116%;
  letter-spacing: 0.24px;
`;

// INPUT
export const ButtonSend = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: opacity 0.3s;
  & svg {
    pointer-events: none;
    @media ${breakpoints.mobile} {
      width: 4.69vw;
      height: 4.69vw;
    }
  }

  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.3;
  }
  @media ${breakpoints.desktopXl} {
    width: 3.7vw;
  }
  @media ${breakpoints.tabletL} {
    width: 4.6vw;
  }
  @media ${breakpoints.tabletM} {
    width: 6.6vw;
  }
  @media ${breakpoints.mobile} {
    width: 15.4vw;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  overflow-y: visible;
`;
export const Input = styled.input`
  border-radius: 16px;
  padding: 9px 48px 13px 20px;
  width: 100%;
  ${fonts.manrope5}
  ${mixins.fs16}
  line-height: 100%;
  letter-spacing: 0.16px;
  background-color: var(--wh02);
  /* backdrop-filter: blur(40px); */
  transition: background-color 0.3s, color 0.3s;
  text-overflow: ellipsis;
  @media ${breakpoints.desktopXl} {
    padding: 0.7vw 3.75vw 1.016vw 1.56vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.88vw 4.69vw 1.27vw 1.95vw;
  }

  @media ${breakpoints.tabletM} {
    padding: 1.21vw 6.46vw 1.75vw 2.69vw;
  }

  @media ${breakpoints.mobile} {
    padding: 2.8vw 15vw 4.06vw 6.25vw;
  }

  &::placeholder {
    transition: color 0.3s;
    ${({ valid }) => {
      switch (valid) {
        case VALID_STATE.neutral:
          return `
      color: var(--wh3);
      `;
        case VALID_STATE.bad:
          return `
        color: var(--orange4);
      `;
        case VALID_STATE.good:
          return `
        color: var(--green3);
      `;
      }
    }}
  }
  &:hover {
    background-color: var(--wh04);
  }
`;
export const EmailInput = styled(Input)`
  ${({ valid }) => {
    switch (valid) {
      case VALID_STATE.neutral:
        return `
      color: var(--wh6);
      `;
      case VALID_STATE.bad:
        return `
        color: var(--orange9);
        border: 1px solid var(--orange6);
        background-color: var(--orange02);
        &:hover {
          background-color: var(--orange04);
        }
      `;
      case VALID_STATE.good:
        return `
        color: var(--green9);
        border: 1px solid var(--green6);
        background-color: var(--green02);
        &:hover {
          background-color: var(--green04);
        }
      `;
    }
  }}
`;
export const NameInput = styled(Input)`
  margin-top: 8px;

  ${({ valid }) => {
    switch (valid) {
      case VALID_STATE.neutral:
        return `
      color: var(--wh6);
      `;
      case VALID_STATE.bad:
        return `
        color: var(--orange9);
        background-color: var(--orange02);
        &:hover {
          background-color: var(--orange04);
        }
      `;
      case VALID_STATE.good:
        return `
        color: var(--green9);
        border: 1px solid var(--green6);
        background-color: var(--green02);
        &:hover {
          background-color: var(--green04);
        }
      `;
    }
  }}
`;
export const ErrorLabel = styled.p`
  color: var(--orange9);
  ${mixins.fs12};
  line-height: 116%;
  letter-spacing: 0.24px;
  margin-top: 8px;
  height: 28px;
  transition: height 0.3s, opacity 0.3s, margin-top 0.3s;
  padding-left: 8px;
  @media ${breakpoints.mobile} {
    padding-left: 2.508vw;
    height: 15vw;
    margin-top: 2.5vw;
    max-width: 78vw;
    padding-left: 2.5vw;
  }
  ${({ hide }) =>
    hide &&
    `
    margin-top: 0px;
    height: 0px !important;
    opacity: 0;
    `}

  ${({ valid }) => {
    switch (valid) {
      case VALID_STATE.neutral:
        return `
    margin-top: 0px !important;
    height: 0px !important;
    opacity: 0;
      `;
      case VALID_STATE.bad:
        return `
        color: var(--orange9);
      `;
      case VALID_STATE.good:
        return `
        color: var(--green9);
      `;
    }
  }}
`;

// REGISTER

export const ButtonRegister = styled.button`
  width: 100%;
  transition: height 0.3s, opacity 0.3s, margin-top 0.3s, background-color 0.3s,
    color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mixins.fs16}
  line-height: 100%;
  letter-spacing: 0.16px;
  border-radius: 16px;
  color: var(--wh6);
  background-color: var(--wh12);
  ${({ hide }) =>
    hide
      ? `
    margin-top: 16px;
      height: 52px;
  @media ${breakpoints.desktopXl} {
    height: 4.066vw;
  }
  @media ${breakpoints.tabletL} {
    height: 5.083vw;
  }
  @media ${breakpoints.tabletM} {
    height: 6.999vw;
  }
  @media ${breakpoints.mobile} {
    height: 16.301vw;
  }`
      : `
    margin-top: 0px;
    height: 0px;
    opacity: 0;
    `}
  &:hover {
    background-color: var(--wh08);
  }
  &:disabled {
    background-color: var(--wh02);
    color: var(--wh12);
  }
`;

// MOBILE

export const PopupHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  z-index: 1;
  padding: 5vw 2.5vw;
  background-color: #141414;
  ${({ openSettings }) => `
  grid-template-columns: ${openSettings ? "auto auto" : "repeat(3, 1fr)"};
  `}
`;
export const Artel = styled.span`
  grid-column: 2;
  justify-self: center;
  width: 13.7vw;
  height: 7.5vw;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;
export const CloseButton = styled.button`
  width: 6.25vw;
  height: 6.25vw;
  grid-column: 3;
  justify-self: end;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;
export const Policy = styled(Link)`
  color: var(--wh12);
  ${fonts.manrope4}
  ${mixins.fs8}
  line-height: 125%;
  letter-spacing: 0.04em;
  transition: color 0.3s;
  margin: 12px auto 0;
  display: block;
  width: fit-content;
  @media ${breakpoints.desktopXl} {
    margin-top: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    margin-top: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    margin-top: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    margin-top: 3.762vw;
  }
  &:hover {
    color: var(--wh3);
  }
  &:active {
    color: var(--wh12);
  }
`;
