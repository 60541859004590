import { createSlice } from "@reduxjs/toolkit";

const campusPromoSlice = createSlice({
  name: "campusPromo",
  initialState: {
    data: null,
    loading: false,
    error: "",
  },
  reducers: {
    fetchCampusPromoSuccess(state, action) {
      state.data = action.payload;
    },
    fetchCampusPromoLoading(state, action) {
      state.loading = action.payload;
    },
    fetchCampusPromoError(state, action) {
      state.error = action.payload;
    },
  },
});

export const campusPromoReducer = campusPromoSlice.reducer;
export const {
  fetchCampusPromoSuccess,
  fetchCampusPromoLoading,
  fetchCampusPromoError,
} = campusPromoSlice.actions;
