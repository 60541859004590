import { configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { alertRetryReducer } from "./alertRetrySlice";
import { campusPageReducer } from "./fetchSlices/campusPageSlice";
import { campusPromoReducer } from "./fetchSlices/campusPromoSlice";
import { campusReducer } from "./fetchSlices/campusSlice";
import { cardsReducer } from "./fetchSlices/cardsSlice";
import { filtersReducer } from "./fetchSlices/filtersSlice";
import { hbCardReducer } from "./fetchSlices/hbCardSlice";
import { hbCardsReducer } from "./fetchSlices/hbCardsSlice";
import { hbCheckedTagsReducer } from "./fetchSlices/hbCheckedTagsSlice";
import { hbTagsReducer } from "./fetchSlices/hbTagsSlice";
import { legalPageReducer } from "./fetchSlices/legalPageSlice";
import { legalReducer } from "./fetchSlices/legalSlice";
import { teamReducer } from "./fetchSlices/teamSlice";
import { userReducer } from "./fetchSlices/userSlice";
import { filterReducer } from "./filterSlice";
import { prevSearchReducer } from "./prevSearchSlice";
import { searchInputReducer } from "./searchInputSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducerPrevSearch = persistReducer(
  persistConfig,
  prevSearchReducer
);
const persistedReducerHbCheckedTags = persistReducer(
  persistConfig,
  hbCheckedTagsReducer
);

export const store = configureStore({
  reducer: {
    // persisted
    prevSearch: persistedReducerPrevSearch,
    hbCheckedTags: persistedReducerHbCheckedTags,

    searchInput: searchInputReducer,
    cards: cardsReducer,
    filter: filterReducer,
    team: teamReducer,
    availableFilters: filtersReducer,
    user: userReducer,
    alertRetry: alertRetryReducer,
    hbCard: hbCardReducer,
    hbCards: hbCardsReducer,
    hbTags: hbTagsReducer,
    campus: campusReducer,
    campusPage: campusPageReducer,
    campusPromo: campusPromoReducer,
    legal: legalReducer,
    legalPage: legalPageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
