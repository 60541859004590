import { createSlice } from "@reduxjs/toolkit";

const legalPageSlice = createSlice({
  name: "legalPage",
  initialState: {
    data: null,
    loading: false,
    error: "",
  },
  reducers: {
    fetchLegalPageSuccess(state, action) {
      state.data = action.payload;
    },
    fetchLegalPageLoading(state, action) {
      state.loading = action.payload;
    },
    fetchLegalPageError(state, action) {
      state.error = action.payload;
    },
  },
});

export const legalPageReducer = legalPageSlice.reducer;
export const {
  fetchLegalPageSuccess,
  fetchLegalPageLoading,
  fetchLegalPageError,
} = legalPageSlice.actions;
