import { createSlice } from "@reduxjs/toolkit";

const legalSlice = createSlice({
  name: "legal",
  initialState: {
    data: [],
    loading: false,
    error: "",
  },
  reducers: {
    fetchLegalSuccess(state, action) {
      state.data = action.payload;
    },
    fetchLegalLoading(state, action) {
      state.loading = action.payload;
    },
    fetchLegalError(state, action) {
      state.error = action.payload;
    },
  },
});

export const legalReducer = legalSlice.reducer;
export const { fetchLegalSuccess, fetchLegalLoading, fetchLegalError } =
  legalSlice.actions;
