export const ROUTES = {
  PROMO: "/fonts",
  LIBRARY: "/fonts/library",
  THANKS: "/fonts/thanks",
  HANDBOOK: "/handbook",
  FOUNDATION: "/artpreparation",
  CAMPUS: "/campus",
  CAMPUS_THANKS: "/campus/graphic-metaphors/thanks",
  CAMPUS_GRAPHIC: "/campus/graphic-metaphors",
  CAMPUS_CLASSROOM_GRAPHIC: "/campus/classroom/graphic-metaphors",
  CAMPUS_CLASSROOM_FIGMA: "/campus/classroom/figma",
  ORDER: "/campus/graphic-metaphors/order",
  LEGAL: "/legal",
};
export const isNotPromo = (page) => page !== ROUTES.PROMO;
export const isPromo = (page) => page === ROUTES.PROMO;
export const isLibrary = (page) => page === ROUTES.LIBRARY;
export const isThanks = (page) => page === ROUTES.THANKS;
export const isHandbook = (page) => page === ROUTES.HANDBOOK;
export const isHandbookSlug = (page) => page.includes(ROUTES.HANDBOOK);
export const isOnlyHandbookSlug = (page) =>
  isHandbookSlug(page) && !isHandbook(page);

export const isFoundation = (page) => page === ROUTES.FOUNDATION;
export const isFoundationSlug = (page) => page.includes(ROUTES.FOUNDATION);
export const isOnlyFoundationSlug = (page) =>
  isFoundationSlug(page) && !isFoundation(page);

export const isCampus = (page) =>
  page === ROUTES.CAMPUS_CLASSROOM_GRAPHIC ||
  page === ROUTES.CAMPUS_CLASSROOM_FIGMA;
export const isCampusSlug = (page) =>
  page.includes(ROUTES.CAMPUS_CLASSROOM_GRAPHIC) ||
  page.includes(ROUTES.CAMPUS_CLASSROOM_FIGMA);
export const isOnlyCampusSlug = (page) => isCampusSlug(page) && !isCampus(page);

export const isOrder = (page) => page === ROUTES.ORDER;
export const isCampusPromo = (page) => page === ROUTES.CAMPUS_GRAPHIC;

export const isLegal = (page) => page === ROUTES.LEGAL;
export const isLegalSlug = (page) => page.includes(ROUTES.LEGAL);
export const isOnlyLegalSlug = (page) => isLegalSlug(page) && !isLegal(page);
