import { fonts } from "@/styles/variables/variables";
import { createGlobalStyle } from "styled-components";

export const styles = `


* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // border: 1px solid pink !important;
}


body {

  --kk: #f5f8ff;
	--blue: #1b57f5;
	--peach: #fdbbbb;
	--blue-20: #212b45;
	--blue-30: #1b367e;
	--blue-60: #7386ba;
	--blue-70: #9baad4;
	--blue-80: #e3eafc;
	--blue-90: #edf1fd;
	--accent: #b3ffab;

  --graphit-bb: #7f7f7f;

  --color-green: #45E786;
  --color-background: #0D0D0D;


  --wh9:rgba(255, 255, 255, 0.9);
  --wh8:rgba(255, 255, 255, 0.8);
  --wh7:rgba(255, 255, 255, 0.7);
  --wh6:rgba(255, 255, 255, 0.6);
  --wh5:rgba(255, 255, 255, 0.5);
  --wh4:rgba(255, 255, 255, 0.4);
  --wh2:rgba(255, 255, 255, 0.2);
  --wh3:rgba(255, 255, 255, 0.3);
  --wh12:rgba(255, 255, 255, 0.12);
  --wh08:rgba(255, 255, 255, 0.08);
  --wh06:rgba(255, 255, 255, 0.06);
  --wh04:rgba(255, 255, 255, 0.04);
  --wh02:rgba(255, 255, 255, 0.02);
  --wh01:rgba(255, 255, 255, 0.01);
  --orange9:rgba(235, 91, 11, 0.90);
  --orange6:rgba(235, 91, 11, 0.60);
  --orange4:rgba(235, 91, 11, 0.40);
  --orange2:rgba(235, 91, 11, 0.2);
  --orange08:rgba(235, 91, 11, 0.08);
  --orange04:rgba(235, 91, 11, 0.04);
  --orange02:rgba(235, 91, 11, 0.02);
  --greenDark:#0D2315;
  --green02:rgba(69, 231, 134, 0.02);
  --green04:rgba(69, 231, 134, 0.04);
  --green6:rgba(69, 231, 134, 0.60);
  --green9:rgba(69, 231, 134, 0.90);
  --green08:rgba(69, 231, 134, 0.08);
  --green3:rgba(69, 231, 134, 0.30);
  --green16:rgba(69, 231, 134, 0.16);
  --green12:rgba(69, 231, 134, 0.12);
  
  // --- new fonts
  --manrope5:rgba(255, 255, 255, 0.04);
  

  height: 100%;
  background: var(--color-background);
  // line-height: 1.35;
  color: var(--wh3);
}


main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-x: hidden; */

  
}

input, input:hover, input:active, input:focus {
outline: none;
}

textarea, textarea:hover, textarea:active, textarea:focus {
outline: none;
}

img {
-ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

hr {
  margin: 0;
  border: none;
}

p {
margin: 0;
padding: 0;
}

ul, li {
display: block;
padding: 0;
margin: 0;
-webkit-box-sizing: border-box;
      box-sizing: border-box;
}

div, p, a, span, input, form, button {
-webkit-box-sizing: border-box;
      box-sizing: border-box;
}

a {
font-weight: inherit;
letter-spacing: inherit;
font-size: inherit;
}

button {
border: none;
}

button:focus {
outline: none;
}

ul, li {
display: block;
padding: 0;
margin: 0;
-webkit-box-sizing: border-box;
      box-sizing: border-box;
}

@media (max-width: 1260px) {
  .no-scroll-mobile {
    position: fixed;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
`;

export const GlobalStyle = createGlobalStyle`
    ${styles}
`;
export const GlobalStyleCampus = createGlobalStyle`
    ${styles};
    body{
      ${fonts.artel4}
      color: var(--blue-30);
      background: var(--kk)
    }
`;
